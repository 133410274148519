import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import styled from "styled-components";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const MultiColumnRow = styled.div`
    display: flex;
`;
export const Column = styled.div`
    width: 100%;
`;
export const ColumnSeparator = styled.div`
    border-left: 1px solid ${props => props.theme.colors.decorative};
    margin-left: ${props => props.theme.spacings.normal};
    margin-right: ${props => props.theme.spacings.normal};
`;
export const _frontmatter = {};
const layoutProps = {
  MultiColumnRow,
  Column,
  ColumnSeparator,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <p>{`Your `}<em parentName="p">{`Premium`}</em>{` membership to `}<em parentName="p">{`TuringTrader.com`}</em>{` gives you access to all `}<a parentName="p" {...{
        "href": "/portfolios/?tag=premium"
      }}>{`our best-in-class portfolios`}</a>{`. These portfolios should address the needs of most investors. Our `}<em parentName="p">{`account groups`}</em>{` feature, available with an `}<em parentName="p">{`Infinite`}</em>{` membership to our site, provides additional helpful features, which are especially useful for investors with many accounts or more sophisticated needs.`}</p>
    <Border mdxType="Border">
      <p css={`text-align: center;`}>
        <p><em parentName="p">{`Account groups`}</em>{` are only available to members of our `}<strong parentName="p">{`Infinite`}</strong>{` plan.`}</p>
        <ButtonPrimary text="compare our plans" to="/sign-up/" mdxType="ButtonPrimary" />
      </p>
    </Border>
    <p>{`In particular, our account groups feature allows investors to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Create custom meta-portfolios`}</strong>{`:  By grouping several of our strategies, investors can create their own meta-portfolios. These groups offer the same diversification benefits as our `}<a parentName="li" {...{
          "href": "/articles/all-stars-portfolios/"
        }}>{`All-Stars portfolios`}</a>{` but more control.`}</li>
      <li parentName="ul"><strong parentName="li">{`Consolidate asset allocation`}</strong>{`: Investors can review the consolidated asset allocation for their account groups. This information can then be used to trade the groups at once or to analyze the group's exposure to the various assets.`}</li>
      <li parentName="ul"><strong parentName="li">{`Consolidate charts and metrics`}</strong>{`: Investors can run a backtest to simulate their account groups' historical performance. This information helps gather deeper insight into recent risks and returns and optimize the portfolio composition accordingly.`}</li>
    </ul>
    <MultiColumnRow mdxType="MultiColumnRow"><Column mdxType="Column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "463px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/45585db289b8d1aedcb35c887df17070/71ce0/account-rename.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRElEQVQoz4XQS08CMRSG4fn//8GdCzdqQozGhbeoCxMBFbkJw0UGR5jBkbm2neJrWsS4URdPer5eTpPjeK8LLm9qNBoNqrUa1VoV/9VHrzRSScvUQRhy99jmsfNEq9NjPJlS6hKp1Pc9UzvLJCdOMwqpSLOCNBckmSArBHkhbDaEUrZxqTVCluRCWUlWUMhy/T4XOGe3E7b2W+wc96hcDNk9cdk+6LB3OqByPmD/1LXr3olrHV0NObwe0x0F3LefmQYZ9daIRnfCS5jiuF5EvT2j3pnRdENag5BmP7C5O1ow9CLcScTT+I2HXkCzH+LNYmaLGH8eEWcSzw/x52/EqcB5T3Km8yVhlLCIUvxgyct8SZzmgGa1Kvn4MLTNmz0zP6016msUhqkdVSoMIeXXgNdMLqT8Xi2xJuTvnL8ON41/fvSfT+6DEDUhjDoSAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "account rename",
                "title": "account rename",
                "src": "/static/45585db289b8d1aedcb35c887df17070/71ce0/account-rename.png",
                "srcSet": ["/static/45585db289b8d1aedcb35c887df17070/5a46d/account-rename.png 300w", "/static/45585db289b8d1aedcb35c887df17070/71ce0/account-rename.png 463w"],
                "sizes": "(max-width: 463px) 100vw, 463px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Column><ColumnSeparator mdxType="ColumnSeparator" /><Column mdxType="Column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "380px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a91ccd16264aa12dbcf42b8b0fd2faa7/3f520/add-to-account-group.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "52%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACMElEQVQoz32Py04TYRhA+0auxYWXKGhSJSoLJT6BC1/AhUtdmrgwxKgkukARKYkiFxsKFCyUQi8zbaHTdi60hFvb6dza6bTHMIQQEuOXnJz//xYn+QKvRxf4EMowMr7Fu4kko1Np3k+m+Pwjw8dQmtmVArGkzJ+kzFpKIZ5RSAgqm6JKMquSymmk8xpCYY9Xb6cJTM0k0LR9hKyKmNOQihUKUsX3dmGX2uEhTrOG06zTNuq4ZgPXrONZDbp2g56j+wabsdASgbloDq/jYJoGrZaNZZvYtun/HcfCME2ahoneNKjrTeoNHcOy0E92hklDNziu63iey+TsBoHZ5SxHR0eIeQmprFKSNYplFVmr+G+ppKDuVinJKsWyglap+sjqLlplD0WrUCjK6HqD734wmuN0uvTo+j6n5++6PQ/vBM+j43m4Hdf3GW234xcmZuKnJ0tak1S+hlhosJ45ZFM8ZqfcRNipU1INbLuLbXdotdv/xHKc8+B8dJtqTWe1IJGQy6xJJeJFmU1ZYaMos6UopDSF8sEBHde7EGq7rm/baZ0Hfy2JvKg+40H6Jk/EQYazQYbEWwwJ/dwXbzAsBHksBLmTusJiNQIumI7lR84wLNsPjk+vE1hYl3i+/5SBrT4eiUEGhesMZC5zT7jG7Uwfd9PXeCj2czV1idhxlP/Nz4U0gZFP80RiCcbmZ/ganuNb+DcT4fAFj4fnCIUjLK6kWV5LsxS7SGQ1RTyZ5+WbL/wFYw6+fUIhidAAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "add to account group",
                "title": "add to account group",
                "src": "/static/a91ccd16264aa12dbcf42b8b0fd2faa7/3f520/add-to-account-group.png",
                "srcSet": ["/static/a91ccd16264aa12dbcf42b8b0fd2faa7/5a46d/add-to-account-group.png 300w", "/static/a91ccd16264aa12dbcf42b8b0fd2faa7/3f520/add-to-account-group.png 380w"],
                "sizes": "(max-width: 380px) 100vw, 380px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Column></MultiColumnRow>
    <h2>{`Creating Account Groups`}</h2>
    <p>{`Creating an account group is straightforward:`}</p>
    <ol>
      <li parentName="ol">{`Go to your `}<a parentName="li" {...{
          "href": "/dashboard/"
        }}>{`dashboard`}</a></li>
      <li parentName="ol">{`Open an account you'd like to add to the group`}</li>
      <li parentName="ol">{`Click the pencil icon next to the account name`}</li>
      <li parentName="ol">{`Append an at-sign followed by the name of your group to the account. You can add spaces for readability if you like`}</li>
      <li parentName="ol">{`Go back to the dashboard`}</li>
      <li parentName="ol">{`Repeat steps 2-5 for any other account you want to add to the group`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "999px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2482240c13e393fd1ae2718e84bc0a0a/20c85/account-group-on-dash.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.666666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAZklEQVQI102M2wrDMAxD+///OXrJ1qa2E9t9OSNllD0IcYSkqYryWgrdnbwuIvN2NeNzHPiPrTWqyNMZeu877W83NKkp67YicpLhhHcyHTkrpWz44HBaM1SFGJ3odz4vM6b67MbhF+tXmpdkyihbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "account group on dash",
            "title": "account group on dash",
            "src": "/static/2482240c13e393fd1ae2718e84bc0a0a/20c85/account-group-on-dash.png",
            "srcSet": ["/static/2482240c13e393fd1ae2718e84bc0a0a/5a46d/account-group-on-dash.png 300w", "/static/2482240c13e393fd1ae2718e84bc0a0a/0a47e/account-group-on-dash.png 600w", "/static/2482240c13e393fd1ae2718e84bc0a0a/20c85/account-group-on-dash.png 999w"],
            "sizes": "(max-width: 999px) 100vw, 999px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now see the account group on your dashboard. Note that you can adjust the allocation to the group's tranches by editing the value for the individual accounts in the group.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "994px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/342cb3589d5cd6ae09b84e4bb87900cb/75609/account-group-composition.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10.666666666666668%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAU0lEQVQI123KQQqAMAxEUe9/SxFFGlMsmiZ18wVXSl08/mJmOK0yTgk7D64wwo3mr1ZjXhIpCXaUz9YJZ7DqrLIjWojwjruTsyKboKq0Fr+/RwtuyNKaehQ5Wk8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "account group composition",
            "title": "account group composition",
            "src": "/static/342cb3589d5cd6ae09b84e4bb87900cb/75609/account-group-composition.png",
            "srcSet": ["/static/342cb3589d5cd6ae09b84e4bb87900cb/5a46d/account-group-composition.png 300w", "/static/342cb3589d5cd6ae09b84e4bb87900cb/0a47e/account-group-composition.png 600w", "/static/342cb3589d5cd6ae09b84e4bb87900cb/75609/account-group-composition.png 994w"],
            "sizes": "(max-width: 994px) 100vw, 994px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Analyzing Account Groups`}</h2>
    <p>{`Clicking the `}<em parentName="p">{`subtotal`}</em>{` line of an account group takes you to the `}<em parentName="p">{`account group analysis`}</em>{` screen. At the top of that screen, you can review the portfolio composition with a separate line for each strategy used in the group.`}</p>
    <p>{`You can also analyze your total investment portfolio across all accounts or account groups. To do so, click the `}<em parentName="p">{`total`}</em>{` line at the bottom of the table titled `}<em parentName="p">{`your investments`}</em>{` on your dashboard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "990px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ed24161ed2d6b852b68a7c614add9fa7/7a3d6/account-group-assets.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAy0lEQVQY012O0bKDMAhE/f+/7FRtooiaBALeh73T1HZqHxhmObBsFyJj2xP+XFFSwlObStOHKbwKrMqlSykIcQHRiirlwrqUC9wr3A05Jdz7O2ghrNsKZoaqwK3CznKzNgsxYBgHEFG7ffNu5oxqDj8OZBHEaUacCfPCeD6rZjD3rzogqo1PMyEXabf15N3EGX68DPeUcet7DCGgfzywMMMvZq+9IoIwTRjHgD2lNnvzLhX9CBEFnZ+JGQuvKKo/Cb0lXLet7baEX+wfT3KA62yB7iQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "account group assets",
            "title": "account group assets",
            "src": "/static/ed24161ed2d6b852b68a7c614add9fa7/7a3d6/account-group-assets.png",
            "srcSet": ["/static/ed24161ed2d6b852b68a7c614add9fa7/5a46d/account-group-assets.png 300w", "/static/ed24161ed2d6b852b68a7c614add9fa7/0a47e/account-group-assets.png 600w", "/static/ed24161ed2d6b852b68a7c614add9fa7/7a3d6/account-group-assets.png 990w"],
            "sizes": "(max-width: 990px) 100vw, 990px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A little further down, you will find a table with the consolidated asset allocation. This table combines the assets held by all the accounts in the group, showing a single line for each stock or fund.`}</p>
    <br />
    <MultiColumnRow mdxType="MultiColumnRow"><Column mdxType="Column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "995px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ab87f5f64969b260d6a54b73bfb63eb8/4cdf7/backtest-metrics.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA70lEQVQoz2WP2XbDIAxE/f9/2TpehMHsm/swPYI0xcmDDgwarkZTTAkkdpAQICKE4HHVgpITSsm3yjlBHAStFWotTUspoJTsnlow+RjxNT/wvSygQ0Jpg5i4eQ1VUa4LqRSsRHA+oF4/yKx3gm26+yZOKJXCoRToOFqdxnTIq0o7U87QQ4+BUp037+RDwLyumJcVuxCwzoGHcN2hHcge3oATMXAjAR/if8IQIzYirNvezPI8YZ1v4BHGHxhohvf3xK+VeYrSGtZ7OO8/0nESPvn91J8r//UbkIU2Fsa6Nt3Yfh9NLeETMA5jzeuOCX8BcMkb3oeH9IMAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "backtest metrics",
                "title": "backtest metrics",
                "src": "/static/ab87f5f64969b260d6a54b73bfb63eb8/4cdf7/backtest-metrics.png",
                "srcSet": ["/static/ab87f5f64969b260d6a54b73bfb63eb8/5a46d/backtest-metrics.png 300w", "/static/ab87f5f64969b260d6a54b73bfb63eb8/0a47e/backtest-metrics.png 600w", "/static/ab87f5f64969b260d6a54b73bfb63eb8/4cdf7/backtest-metrics.png 995w"],
                "sizes": "(max-width: 995px) 100vw, 995px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Column><ColumnSeparator mdxType="ColumnSeparator" /><Column mdxType="Column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "991px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e818504ffbc5a38d4197ee759932a42e/1b19f/backtest-equity-chart.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "55.99999999999999%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACcElEQVQoz12RTYhNYRjHz0KUoiyxsJBilBIbsVCisCKkoaxsbaUsZG+rMPkYUyymURMrWbCQGDGYUWPca86Zc+897/m495z3vOfjfc+9P92DBk/9ev7Ps/g9b71WqTX/U+gSrQ1JIWlGDTwR0nB8/CBAZRlFqcmKEpUXVJUBBgxLG4NVlCVD8qKo+ZN1aQjzkPn4M56f8OmbT9SLSVJFVWmgqkVhT3FraoHrd2bxw96KcEVakuUFKs/Jcw3DB9BnpSpaQvL8jcu127PsGn2Gte8eG46NY7fDX8KsKGrJUFjqEgbmt6RPkmbMzPmMP1vk+tgsp6+8ZOTcFGsPjbPq4F3WHR9jw9EJtp59jOOFWMYY6A/qy1VfI1XB4lLM01cOl268ZfeFadYeeoh1YAxr/xjWvrusPnifdUcesP7IQ9YfnmDN/sdsPDqFKyRWlCbMuw6vP7W4evMDJy6/YO/FKTadmGDzyQm2nHrE9tFJRs5PMjL6hJ1np9lxZprtp38xzNtOPWHPhae4Xowls4ymEMw4C7xrfuejvURDdGh3Q4JI4fkZHZEhRI7wM9pC0vFTRKDq7g27UIRRSqYklpcGtWB2ya4lSaxxgy52JGinHYQSeMqj0bX5EblEqktbeiz1XNykw3LcwZUt5mwHlSqsrkqY6Xzhs72MiCRfRZP37Xnm/EWEjOgkAZ4M+R46NMMWgYxpxwGNaJlG12EuXMBLQt7a84Tx8FO0gWpAq/yBm9skZQ9jSipjqLTB/KZvqhqtdb0fZqM1uc6odJ9BpZFxhJXnCpUktLsOUSwoU0UqY5SMSWXvv9z7J6fJX3PSQ8qEnw8LAsQpJCEwAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "backtest equity chart",
                "title": "backtest equity chart",
                "src": "/static/e818504ffbc5a38d4197ee759932a42e/1b19f/backtest-equity-chart.png",
                "srcSet": ["/static/e818504ffbc5a38d4197ee759932a42e/5a46d/backtest-equity-chart.png 300w", "/static/e818504ffbc5a38d4197ee759932a42e/0a47e/backtest-equity-chart.png 600w", "/static/e818504ffbc5a38d4197ee759932a42e/1b19f/backtest-equity-chart.png 991w"],
                "sizes": "(max-width: 991px) 100vw, 991px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Column><ColumnSeparator mdxType="ColumnSeparator" /><Column mdxType="Column">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "987px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/201127c5c0400a89fc6d117089ee73e8/d1d24/backtest-annual-chart.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "57.666666666666664%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACFUlEQVQoz42SXWsTQRSG95d7KwUrCGK9E5SCpFiFWmkRi1SKjdEKpm3StE1smqS7m89mvnZmd/vITpJeBEEHXp4z58yeHea8gXWOQomzc8731hIOJaPxLdEgZiiHGJP4/KJurPVcfFsoSBbJJWmTUGuO6A8njKZDQhkyFQJj502t4y5P72NjFEopApMkFFJG+8M6SXyzxBk0ExLrcC7F2XR2s6RoYBmZMbvlNtoYtDMYKZBSEmSZI8ssI9f3zfI8Be6o1G/4XDunWMoUP7VIo5FuSpqmnIZtnm2e0IpjIhPjtEZrTdDq3nLc7POr08Yh2DpoMpwoSgdVXu5V6Y2HqET7xtuVBp+qF5xfjXm8WaG0X2P1bZmTbo/caoQUBE/e/OTFVoO1D985urri0UaFtXcnPN35yspGmec7P1j/dMp2+ZLV919YKX3j9W6Lh6VDXu1VebC+z3G3A7lFFm/Y6EXUwmvqnYizTp/LsM9Fb8ZGN6YVDjhtR9SvY857sa+dhT3qnZBmOOB3PODjUYvD+hlZlhDkuWOQRhRveZdn5Fl6r2KKmWfmY5/PU2xqPNPUATnRSNCIL9BaEujEEJlwNuVkPuW5zBIXcTGghTsKR7jMoc0EqSSBcZYoidCFv7xhl2X/mTMFtUJpRaC1YCBv0EpgtPw/qSVqiRZjhBAEsjCkmOIpBUpJr/t4zr/VpBCzupf0N/8DAdCJ55oRNr4AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "backtest annual chart",
                "title": "backtest annual chart",
                "src": "/static/201127c5c0400a89fc6d117089ee73e8/d1d24/backtest-annual-chart.png",
                "srcSet": ["/static/201127c5c0400a89fc6d117089ee73e8/5a46d/backtest-annual-chart.png 300w", "/static/201127c5c0400a89fc6d117089ee73e8/0a47e/backtest-annual-chart.png 600w", "/static/201127c5c0400a89fc6d117089ee73e8/d1d24/backtest-annual-chart.png 987w"],
                "sizes": "(max-width: 987px) 100vw, 987px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Column></MultiColumnRow>
    <br />
    <p>{`Often, investors want to analyze the historical performance of an account group. To do so, click on the `}<em parentName="p">{`run backtest`}</em>{` button. The backtest will start, and a short moment later, a table with the account group's key metrics will appear, along with charts of the equity curve, drawdowns, and annual returns.`}</p>
    <ButtonPrimary text="go to dashboard" to="/dashboard/" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      